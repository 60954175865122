// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tvzQbgyMPPNsSwU4uLCy{cursor:pointer;font-family:\"Source Sans Pro\",sans-serif;font-size:14px;line-height:24px;font-weight:600;color:#000;background-color:rgba(0,0,0,0);border:none;display:flex;align-items:center;padding:0}", "",{"version":3,"sources":["webpack://./src/components/atoms/buttons/BackButton/styles.module.scss","webpack://./src/styles/_fonts.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGA,sBACE,cAAA,CACA,wCCJoB,CDKpB,cAAA,CACA,gBAAA,CACA,eAAA,CACA,UETM,CFUN,8BAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":["@import 'src/styles/colors';\n@import 'src/styles/fonts';\n\n.button {\n  cursor: pointer;\n  font-family: $content-font-family;\n  font-size: 14px;\n  line-height: 24px;\n  font-weight: 600;\n  color: $black;\n  background-color: transparent;\n  border: none;\n  display: flex;\n  align-items: center;\n  padding: 0;\n}\n","$headings-font-family: 'Source Serif Pro', sans-serif;\n$content-font-family: 'Source Sans Pro', sans-serif;\n","$black: #000000;\r\n$white: #FFFFFF;\r\n\r\n$primary-20: #00164F;\r\n$primary-30: #13307E;\r\n$primary-40: #3B5AAE;\r\n$primary-50: #708DE4;\r\n$primary-80: #DBE1FF;\r\n\r\n$gradient-40: linear-gradient(90deg, #00B39B, #3B71F7);\r\n\r\n$error-20: #410001;\r\n$error-30: #7D0008;\r\n$error-40: #E24D39;\r\n$error-50: #FF8A7C;\r\n$error-80: #FFDAD5;\r\n\r\n$neutral-20: #46474A;\r\n$neutral-30: #76777A;\r\n$neutral-40: #AEAFB1;\r\n$neutral-60: #DBDCDF;\r\n$neutral-80: #EEF0F5;\r\n\r\n$warning-40: #F9E0A2;\r\n$warning-50: #F5D174;\r\n$warning-60: #EFB217;\r\n\r\n$green-30: #006B5D;\r\n$green-50: #00B39B;\r\n$green-70: #80D9CD;\r\n$green-90: #CCF0EB;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "tvzQbgyMPPNsSwU4uLCy"
};
export default ___CSS_LOADER_EXPORT___;
