import React, { FC } from 'react';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import AuthRoute from '~/components/layoutComponents/AuthRoute';
import PrivateRoute from '~/components/layoutComponents/PrivateRoute';
import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { filterPermissionKeysByREADRule } from '~/utils/permissions';

const authRoutes = router.getAuthRoutes();

const Router: FC = () => {
  const profile = useAppSelector((state) => state.profile);

  const filteredReadPermissions = filterPermissionKeysByREADRule(
    profile.permissions,
  );

  const privateRoutes = router.getPrivateRoutesByPermissions(
    filteredReadPermissions,
  );

  return (
    <BrowserRouter>
      <React.Suspense fallback={<FullscreenPreloader />}>
        <Routes>
          {authRoutes.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={<AuthRoute Component={Component} />}
            />
          ))}

          {privateRoutes.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={<PrivateRoute Component={Component} />}
            />
          ))}

          <Route
            path="*"
            element={<Navigate to={router.getSignInRoutePath()} />}
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Router;
