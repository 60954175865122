import React, { FC, useState } from 'react';

import { TextField as MuiTextField, styled, Typography } from '@mui/material';

import Button, { ButtonShape } from '~/components/atoms/buttons/Button';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const StyledTextField = styled(MuiTextField, {
  name: 'StyledTextField',
})(() => ({
  width: '246px',

  '& .MuiInputBase-root': {
    padding: '4px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
    backgroundColor: colors.white,

    '& fieldset': {
      border: '1px solid #E9E7EC',
    },

    '& textarea': {
      fontSize: '16px;',
      letterSpacing: '0.5px',
      lineHeight: '24px',
    },

    '&.Mui-focused': {
      '& fieldset': {
        border: `1px solid ${colors.neutral_30}`,
      },
    },

    '&.Mui-error': {
      '& fieldset': {
        border: `2px solid ${colors.error_30}`,
      },
    },

    // Hiding the increment/decrement arrows
    '& input[type="number"]': {
      WebkitAppearance: 'none', // Chrome, Safari, Edge
      MozAppearance: 'textfield', // Firefox
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none', // Chrome, Safari
        margin: 0,
      },
    },
  },
}));

const extraWordsValues = [10000, 100000];

type Props = {
  isDisabled: boolean;
  currentExtraWords: number;
  onExtraWordsUpdate: (amount: number) => Promise<void>;
};

const ExtraWords: FC<Props> = ({
  isDisabled,
  currentExtraWords,
  onExtraWordsUpdate,
}) => {
  const [wordsQuery, setWordsQuery] = useState<number | string>(
    currentExtraWords,
  );
  const [isWordsInputShown, setIsWordsInputShown] = useState(false);

  const handleCustomWordsChange = (e): void => {
    const value = e.target.value === '' ? '' : Number(e.target.value);
    if (value === '' || value >= 0) {
      setWordsQuery(value);
    }
  };

  const handleCustomWordsSubmit = async (): Promise<void> => {
    onExtraWordsUpdate(Number(wordsQuery));
    setIsWordsInputShown(false);
  };

  return (
    <div className={styles.extra}>
      <Typography
        variant={Typographies.HEADLINE_LARGE}
        component="div"
        className={styles.extra__title}
      >
        Extra words ➕
      </Typography>

      <Typography
        variant={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.extra__text}
      >
        Current user amount of extra words:{' '}
        <b>{currentExtraWords.toLocaleString()}</b>
      </Typography>

      <div className={styles.extra__panel}>
        {extraWordsValues.map((value) => {
          return (
            <Button
              key={value}
              variant="contained"
              disabled={isDisabled}
              shape={ButtonShape.ROUNDED}
              color="neutral"
              onClick={async (): Promise<void> =>
                onExtraWordsUpdate(currentExtraWords + value)
              }
            >
              + {value.toLocaleString()} words
            </Button>
          );
        })}
      </div>

      {isWordsInputShown ? (
        <div className={styles.extra__custom}>
          <StyledTextField
            className={styles.extra__input}
            size="small"
            placeholder="Enter amount of words"
            fullWidth
            value={wordsQuery}
            type="number"
            inputProps={{
              min: 0,
            }}
            onChange={handleCustomWordsChange}
          />

          <Button
            variant="contained"
            disabled={isDisabled || wordsQuery === ''}
            shape={ButtonShape.ROUNDED}
            color="neutral"
            onClick={handleCustomWordsSubmit}
          >
            Set
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          disabled={isDisabled}
          shape={ButtonShape.ROUNDED}
          color="neutral"
          onClick={(): void => setIsWordsInputShown(true)}
        >
          Custom amount
        </Button>
      )}
    </div>
  );
};

export default ExtraWords;
