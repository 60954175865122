import React, { FC } from 'react';

import { ExtendedUserType } from '~/modules/users/types';

import General from './General';
import styles from './styles.module.scss';
import Subscription from './Subscription';

type Props = {
  user: ExtendedUserType;
};

const GeneralInfo: FC<Props> = ({ user }) => {
  return (
    <div className={styles.container}>
      <div className={styles.general}>
        <General user={user} />
      </div>

      <div className={styles.sub}>
        <Subscription user={user} />
      </div>
    </div>
  );
};

export default GeneralInfo;
