import React, { FC } from 'react';

import AttentionImg from '~/assets/images/attention.webp';
import AttentionPreviewImg from '~/assets/images/attention_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';

import styles from './styles.module.scss';

type Props = {
  title: string;
  onSubmit: () => void;
  onRefuse: () => void;
};

const DeleteConfirmationModal: FC<Props> = ({ title, onSubmit, onRefuse }) => {
  return (
    <div>
      <div className={styles.image}>
        <Image src={AttentionImg} preview={AttentionPreviewImg} />
      </div>

      <div className={styles.title}>{title}</div>

      <div className={styles.buttons_container}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Yes
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          color="primary"
          onClick={onRefuse}
        >
          No
        </Button>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
