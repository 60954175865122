import React, { FC, useMemo } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';

import { CheckAuthWrapper } from '~/components/layoutComponents/CheckAuthWrapper';
import { ModalContextProvider } from '~/contexts/modal/ModalContext';
import Router from '~/Router';
import { store } from '~/store';
import createCustomTheme from '~/theme';

import { SnackBarContextProvider } from './components/layoutComponents/snackbar/SnackbarContext';

const themeMode = 'light';

const App: FC = () => {
  const theme = useMemo(() => createCustomTheme(themeMode), [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <SnackBarContextProvider>
          <ModalContextProvider>
            <CheckAuthWrapper>
              <Router />
            </CheckAuthWrapper>
          </ModalContextProvider>
        </SnackBarContextProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
