import React, { FC } from 'react';

import { Typography } from '@mui/material';

import CopyButton from '~/components/atoms/buttons/CopyButton';
import LoadingButton from '~/components/atoms/buttons/LoadingButton';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  isDisabled: boolean;
  oneTimePassword: string;
  onSubmit: () => Promise<void>;
};

const Password: FC<Props> = ({ isDisabled, oneTimePassword, onSubmit }) => {
  const handleCopyClick = async (): Promise<void> => {
    await navigator.clipboard.writeText(oneTimePassword);
  };

  return (
    <div className={styles.password}>
      <Typography variant={Typographies.HEADLINE_LARGE} component="div">
        Onetime password 🔑
      </Typography>

      {oneTimePassword && (
        <Typography
          variant={Typographies.TITLE_MEDIUM}
          component="div"
          className={styles.password__generated}
        >
          <span>{oneTimePassword}</span>
          <CopyButton onClick={handleCopyClick} />
        </Typography>
      )}

      <div className={styles.password__buttons}>
        <LoadingButton
          variant="contained"
          loading={isDisabled}
          color="primary"
          onClick={onSubmit}
        >
          {oneTimePassword ? 'Regenerate' : 'Generate'} password
        </LoadingButton>
      </div>
    </div>
  );
};

export default Password;
