import { ProductType, REGULARITY } from '~/modules/products/types';
import { FORM_FIELDS, UiFieldType } from '~/types/form';

export const ProductsPath = '/products';
export const ProductsTitle = 'Products';
export const ProductsSidebarKey = 'Products';

export const ProductByIdPath = '/products/:id';
export const ProductNewPath = '/products/new';

export const initialProductData: ProductType = {
  id: '',
  name: '',
  price: 0,
  currency: 'USD',
  billing_period: undefined,
  regularity: REGULARITY.ONETIME,
  discount_percentage_ui: 0,
  is_unlimited: false,
  has_trial_period: false,
  is_solid: false,
  trial_amount: 0,
  trial_period: 0, // trial_period -> trial_billing_period (at server)
  trial_words_amount: 0,
  words_amount: 0,
  new_price_ui: 0,
  old_price_ui: 0,
  regularity_ui: REGULARITY.ONETIME,
  description_ui: '',
  details_ui: '',
  is_popular_ui: false,
  created_at: '2022-11-11T11:11:11Z',
  updated_at: '2022-11-11T11:11:11Z',
  /* TODO! We remove these keys since they're not used at server
   * subscription_type
   * trial_currency
   *
   * */
};

export enum CURRENCY {
  DOLLAR = 'USD',
  EURO = 'EUR',
  POUND = 'GBP',
}

export enum PRODUCT_GROUP {
  General = 'General',
  UI = 'UI',
  Trial = 'Trial',
}

export const PRODUCT_GROUP_TABS = Object.values(PRODUCT_GROUP);

export const SCHEMA: {
  ui_schema: Record<string, UiFieldType>;
  json_schema: Record<string, any>;
} = {
  ui_schema: {
    id: {
      group: PRODUCT_GROUP.General,
      max_count: 36,
      props: {
        placeholder: 'acde070d-8c4c-4f0d-9d8a-162843c10333',
        required: true,
      },
      title: 'Product ID (Solid)',
      type: FORM_FIELDS.TEXT,
    },
    name: {
      group: PRODUCT_GROUP.General,
      max_count: 500,
      props: {
        placeholder: 'Detectors Bundle Plan',
        required: true,
      },
      title: 'Product name',
      type: FORM_FIELDS.TEXT,
    },
    price: {
      group: PRODUCT_GROUP.General,
      props: {
        placeholder: '999',
        required: true,
      },
      title: 'Price',
      type: FORM_FIELDS.TEXT,
    },
    currency: {
      group: PRODUCT_GROUP.General,
      props: {
        options: [
          {
            text: CURRENCY.DOLLAR,
            value: CURRENCY.DOLLAR,
          },
          {
            text: CURRENCY.EURO,
            value: CURRENCY.EURO,
          },
          {
            text: CURRENCY.POUND,
            value: CURRENCY.POUND,
          },
        ],
        placeholder: '',
        required: true,
      },
      title: 'Currency',
      type: FORM_FIELDS.SELECT,
    },
    billing_period: {
      group: PRODUCT_GROUP.General,
      props: {
        placeholder: '30',
        required: true,
      },
      title: 'Billing period',
      type: FORM_FIELDS.TEXT,
    },
    regularity: {
      group: PRODUCT_GROUP.General,
      props: {
        options: [
          {
            text: REGULARITY.ONETIME,
            value: REGULARITY.ONETIME,
          },
          {
            text: REGULARITY.WEEK,
            value: REGULARITY.WEEK,
          },
          {
            text: REGULARITY.MONTH,
            value: REGULARITY.MONTH,
          },
          {
            text: REGULARITY.YEAR,
            value: REGULARITY.YEAR,
          },
        ],
        placeholder: '',
        required: true,
      },
      title: 'Regularity',
      type: FORM_FIELDS.SELECT,
    },
    is_unlimited: {
      group: PRODUCT_GROUP.General,
      props: { defaultValue: false, required: true },
      title: 'Is product unlimited?',
      type: FORM_FIELDS.TOGGLE,
    },
    words_amount: {
      group: PRODUCT_GROUP.General,
      props: {
        placeholder: '10000',
        required: true,
      },
      title: 'Words amount',
      type: FORM_FIELDS.TEXT,
    },

    has_trial_period: {
      group: PRODUCT_GROUP.Trial,
      props: { defaultValue: false, required: true },
      title: 'Has trial period?',
      type: FORM_FIELDS.TOGGLE,
    },
    trial_amount: {
      group: PRODUCT_GROUP.Trial,
      props: {
        placeholder: '7',
        required: false,
        defaultValue: 0,
      },
      title: 'Trial amount',
      type: FORM_FIELDS.TEXT,
    },
    trial_currency: {
      group: PRODUCT_GROUP.Trial,
      props: {
        options: [
          {
            text: CURRENCY.DOLLAR,
            value: CURRENCY.DOLLAR,
          },
          {
            text: CURRENCY.EURO,
            value: CURRENCY.EURO,
          },
          {
            text: CURRENCY.POUND,
            value: CURRENCY.POUND,
          },
        ],
        placeholder: '',
        required: false,
      },
      title: 'Trial currency',
      type: FORM_FIELDS.SELECT,
    },
    trial_period: {
      group: PRODUCT_GROUP.Trial,
      props: {
        placeholder: '30',
        required: false,
      },
      title: 'Trial period',
      type: FORM_FIELDS.TEXT,
    },
    trial_words_amount: {
      group: PRODUCT_GROUP.Trial,
      props: {
        placeholder: '2000',
        required: false,
      },
      title: 'Trial words amount',
      type: FORM_FIELDS.TEXT,
    },

    description_ui: {
      group: PRODUCT_GROUP.UI,
      max_count: 500,
      props: {
        placeholder: 'Justdone.ai Plagiarism & AI Detectors Bundle',
        required: true,
      },
      title: 'Product description (UI)',
      type: FORM_FIELDS.TEXTAREA,
    },
    details_ui: {
      group: PRODUCT_GROUP.UI,
      max_count: 500,
      props: {
        placeholder: 'Annual fee $299 ($24.99 per month) for unlimited usage',
        required: false,
      },
      title: 'Product details (UI)',
      type: FORM_FIELDS.TEXTAREA,
    },
    is_popular_ui: {
      group: PRODUCT_GROUP.UI,
      props: { defaultValue: false, required: true },
      title: 'Is product popular? (UI)',
      type: FORM_FIELDS.TOGGLE,
    },
    new_price_ui: {
      group: PRODUCT_GROUP.UI,
      props: {
        placeholder: '999',
        required: true,
      },
      title: 'New price (UI)',
      type: FORM_FIELDS.TEXT,
    },
    old_price_ui: {
      group: PRODUCT_GROUP.UI,
      props: {
        placeholder: '1999',
        required: false,
      },
      title: 'Old price (UI)',
      type: FORM_FIELDS.TEXT,
    },
    discount_percentage_ui: {
      group: PRODUCT_GROUP.UI,
      props: {
        placeholder: '50',
        required: false,
      },
      title: 'Discount percentage (UI)',
      type: FORM_FIELDS.TEXT,
    },
    regularity_ui: {
      group: PRODUCT_GROUP.UI,
      props: {
        options: [
          {
            text: REGULARITY.ONETIME,
            value: REGULARITY.ONETIME,
          },
          {
            text: REGULARITY.WEEK,
            value: REGULARITY.WEEK,
          },
          {
            text: REGULARITY.MONTH,
            value: REGULARITY.MONTH,
          },
          {
            text: REGULARITY.YEAR,
            value: REGULARITY.YEAR,
          },
        ],
        placeholder: '',
        required: true,
      },
      title: 'Regularity (UI)',
      type: FORM_FIELDS.SELECT,
    },
  },
  json_schema: {
    $schema: 'http://json-schema.org/draft-04/schema#',
    properties: {
      id: {
        maxLength: 36,
        minLength: 36,
        type: 'string',
      },
      name: {
        maxLength: 500,
        type: 'string',
      },
      price: {
        type: 'number',
      },
      currency: {
        enum: [CURRENCY.DOLLAR, CURRENCY.EURO, CURRENCY.POUND],
        type: 'string',
      },
      billing_period: {
        type: 'number',
      },
      regularity: {
        enum: [
          REGULARITY.ONETIME,
          REGULARITY.WEEK,
          REGULARITY.MONTH,
          REGULARITY.YEAR,
        ],
        type: 'string',
      },
      is_unlimited: {
        type: 'boolean',
      },
      words_amount: {
        type: 'number',
      },
      has_trial_period: {
        type: 'boolean',
      },
      trial_amount: {
        type: 'number',
      },
      trial_currency: {
        enum: [CURRENCY.DOLLAR, CURRENCY.EURO, CURRENCY.POUND],
        type: 'string',
      },
      trial_period: {
        type: 'number',
      },
      trial_words_amount: {
        type: 'number',
      },
      description_ui: {
        maxLength: 500,
        type: 'string',
      },
      details_ui: {
        maxLength: 500,
        type: 'string',
      },
      is_popular_ui: {
        type: 'boolean',
      },
      new_price_ui: {
        type: 'number',
      },
      old_price_ui: {
        type: 'number',
        minLength: 0,
      },
      discount_percentage_ui: {
        type: 'number',
      },
      regularity_ui: {
        enum: [
          REGULARITY.ONETIME,
          REGULARITY.WEEK,
          REGULARITY.MONTH,
          REGULARITY.YEAR,
        ],
        type: 'string',
      },
    },
    required: [
      'id',
      'name',
      'price',
      'currency',
      'billing_period',
      'regularity',
      'is_unlimited',
      'words_amount',
      'has_trial_period',
      'description_ui',
      'new_price_ui',
      'regularity_ui',
      'is_popular_ui',
    ],
    type: 'object',
  },
};
