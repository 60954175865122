// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".doPCFNY4dXuURs8MtzvK{display:flex;align-items:center;justify-content:space-between}.uqX38hn3xCLQScL6kbL3{width:18px;height:18px;flex-shrink:0}.e47b5wVUSajFv3bfNm6H{margin-left:8px}", "",{"version":3,"sources":["webpack://./src/components/atoms/buttons/CopyButton/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA,sBCDE,YADiB,CAGf,kBAAA,CAGA,6BDHsC,CAExC,sBCSA,UDRkB,CCSlB,WDTkB,CAChB,aAAA,CAGF,sBACE,eAAA","sourcesContent":["@import \"src/styles/mixins\";\n\n.button {\n  @include flex($align: center, $justify: space-between);\n\n  &__icon {\n    @include square(18px);\n    flex-shrink: 0;\n  }\n\n  &__text {\n    margin-left: 8px;\n  }\n}","@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {\n  display: $type;\n  @if ($align) {\n    align-items: $align;\n  }\n  @if ($justify) {\n    justify-content: $justify;\n  }\n  @if ($direction) {\n    flex-direction: $direction;\n  }\n}\n\n@mixin square($size) {\n  width: $size;\n  height: $size;\n}\n\n@mixin circle($size) {\n  width: $size;\n  height: $size;\n  border-radius: 50%;\n}\n\n@mixin backgroundClippedText($background) {\n  background: $background;\n  -webkit-background-clip: text;\n  background-clip: text;\n  background-size: cover;\n  color: transparent;\n}\n\n@mixin ignoreParentsWidth() {\n  width: 100vw;\n  margin-left: calc(50% - 50vw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "doPCFNY4dXuURs8MtzvK",
	"button__icon": "uqX38hn3xCLQScL6kbL3",
	"button__text": "e47b5wVUSajFv3bfNm6H"
};
export default ___CSS_LOADER_EXPORT___;
