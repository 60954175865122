import { ProductType } from '~/modules/products/types';

export enum ONBOARDING_SAVE_KEYS {
  COMPANY_SIZE = 'company_size',
  PROFESSION = 'profession',
  LANGUAGE = 'language',
  POSITION = 'position',
  PURPOSES = 'purposes',
  AI_EXPERIENCE = 'ai_experience',
}

export enum REGULARITY {
  ONETIME = 'onetime',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  REDEMPTION = 'redemption',
  CANCELLED = 'cancelled',
  CANCEL_PENDING = 'cancel_pending',
  PAUSED = 'paused',
  PAUSE_PENDING = 'pause_pending',
}

export interface SubscriptionType {
  id: string; // +
  is_unlimited: boolean; // +
  billing_period: REGULARITY; // +
  product_id: string;
  subscription_price: number; // +
  subscription_name: string; // +
  status: SUBSCRIPTION_STATUS; // +
  started_at: string; // +
  expired_at: string | null; // +
  next_charge_at: string | null; // +
  cancelled_at: string | null; // +
  is_trial: boolean; // +
  cancel_code: string | null; // +
  cancel_message: string | null; // +
  payment_type: 'credit_card' | 'apple_pay' | 'paypal'; //+
  pause_from: string | null; // +
  pause_to: string | null; // +
  created_at: string;
  updated_at: string;
  words_amount_left: number;
  words_amount_total: number;
  words_generated_total: number;
  saved_time_minutes: number;
  response_count_total: number;
}

export type PWAModalExtraFieldsType = {
  last_showed_at: string | null;
  is_showed_first_generation: boolean;
  is_showed_fifth_session: boolean;
};

export type OrdersType = {
  amount: string;
  name: string;
  processed_at: string;
};

export type ExtraFields = {
  ab_tests: Record<string, any>;
  notifications: Record<string, any>;
  highlights: Record<string, any>;
  test_form: Record<string, any>;
  sign_up_at: string | null;
  feedbackV2: Record<string, any>;
  pwa_modal: PWAModalExtraFieldsType;
  cancel_sub_reasons: Record<string, number>;
  rewards: Record<string, number>;
};

export type FeaturesFields = {
  image_generation_tool_enabled: boolean;
};

export type UserType = {
  id: string;
  email: string;
  name: string;
  response_count_total: number;
  is_onboarded: boolean;
  is_stripe_user: boolean;
  detectors_extra_words_left: number;
  is_chrome_extension_installed: boolean;
  created_at: string;
  updated_at: string;
};

export type ExtendedUserType = UserType & {
  avatar: string;
  extra_fields: ExtraFields;
  user_features: FeaturesFields;
  user_product: ProductType;
  user_subscription: SubscriptionType;
  user_onboarding: Record<ONBOARDING_SAVE_KEYS, string>;
  backend_ab_tests: Record<string, string>;
  orders: OrdersType[];
};
