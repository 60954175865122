import { UseFormSetValue } from 'react-hook-form';

import { FORM_FIELDS, UiFieldType } from '~/types/form';

const getDefaultValue = (
  type: FORM_FIELDS,
  props: UiFieldType['props'],
): string | number | boolean => {
  const { options, defaultValue, placeholder } = props;
  const fallbackValue = defaultValue ?? '';

  switch (type) {
    case FORM_FIELDS.SELECT_CUSTOM_OPTION:
      return options?.length ? options[0].value : fallbackValue;

    case FORM_FIELDS.TAGS:
      return placeholder ? placeholder.replace(/\.{3}$/g, '') : '';

    default:
      return fallbackValue;
  }
};

const getNewValue = (
  type: FORM_FIELDS,
  props: UiFieldType['props'],
  initialFormValue: string | number | boolean,
): string | number | boolean => {
  const { options, defaultValue } = props;
  const fallbackValue = initialFormValue || defaultValue;

  switch (type) {
    case FORM_FIELDS.SELECT_CUSTOM_OPTION:
      return fallbackValue ?? '';

    case FORM_FIELDS.SELECT:
      return (
        options?.find(({ value }) => value === initialFormValue)?.value ||
        (options?.length ? options[0].value : '')
      );

    case FORM_FIELDS.COUNTER:
      return fallbackValue ?? 1;

    case FORM_FIELDS.TOGGLE:
      return fallbackValue ?? false;

    default:
      return fallbackValue ?? '';
  }
};

export const resetFormFields = (
  uiSchema: Record<string, UiFieldType>,
  setValue: UseFormSetValue<Record<string, string | number | boolean>>,
  initialFormValue: Record<string, string | number | boolean>,
): void => {
  Object.entries(uiSchema).forEach(([name, data]) => {
    const { type, props } = data;

    const newValue = getNewValue(
      type,
      {
        ...props,
        defaultValue: getDefaultValue(type, props),
      },
      initialFormValue[name],
    );

    setValue(name, newValue);
  });
};
