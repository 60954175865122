import React, { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  Icon: FC<{ isActive?: boolean }>;
  title: string;
  isActive: boolean;
  onClick: () => void;
};

const SideBarItem: FC<Props> = ({ Icon, title, isActive, onClick }) => {
  return (
    <div
      onClick={(): void => onClick()}
      className={clsx(styles.item, { [styles['item--active']]: isActive })}
    >
      <div
        className={clsx(styles.item__icon, {
          [styles['item__icon--active']]: isActive,
        })}
      >
        <Icon isActive={isActive} />
      </div>

      <span className={styles.item__text}>{title}</span>
    </div>
  );
};

export default SideBarItem;
