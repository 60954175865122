import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from '@mui/material';

export type Props = Omit<MuiButtonProps, 'startIcon' | 'endIcon'>;

const IconButton = styled(MuiButton, {
  name: 'StyledIconButton',
})(({ theme }) => ({
  '&': {
    boxShadow: 'none',
    transition: '300ms ease-in-out',
    borderRadius: '10em',
    minWidth: '0px',
    padding: 0,

    '&:hover': {
      boxShadow: 'none',
    },
  },

  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.colors['primary_50'],
    color: theme.colors.white,

    '&:hover': {
      backgroundColor: theme.colors['primary_30'],
    },
  },

  '&.MuiButton-containedSecondary': {
    color: theme.colors.white,
    backgroundColor: theme.colors.black,

    '&:hover': {
      backgroundColor: theme.colors['neutral_80'],
    },
  },

  '&.MuiButton-outlined': {
    border: `1px solid ${theme.colors['neutral_20']}`,
    color: theme.colors.black,

    '&:hover': {
      backgroundColor: theme.colors['neutral_60'],
    },
  },

  '&.MuiButton-sizeSmall': {
    fontSize: '18px',
    width: '40px',
    height: '40px',
  },

  '&.MuiButton-sizeMedium': {
    fontSize: '22px',
    width: '48px',
    height: '48px',
  },

  '&.MuiButton-sizeLarge': {
    fontSize: '26px',
    width: '56px',
    height: '56px',
  },

  '&.Mui-disabled': {
    opacity: '0.4',
  },
}));

export default IconButton;
