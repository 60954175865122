import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { useAutocomplete } from '@mui/lab';
import { styled } from '@mui/material/styles';

import Tag from '~/components/atoms/Tag';
import { colors } from '~/theme/colors';

interface OptionType {
  title: string;
  value: string;
}

const Label = styled('label')`
  padding: 0 0 4px;
  font-weight: 600;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')(
  () => `
  width: 100%;
  border: 1px solid #E2E2EC;
  background-color: ${colors.white};
  min-height: 54px;
  border-radius: 8px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:hover {
    border-color: ${colors.black};
  }

  &.focused {
    border-color: ${colors.primary_30};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${colors.white};
    color: ${colors.black};
    height: 40px;
    box-sizing: border-box;
    padding: 8px 4px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    font-size: 14px;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

const Listbox = styled('ul')(() => {
  return `
  width: 100%;
  max-width: 500px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${colors.white};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  
  & li {
    padding: 8px 12px;
    display: flex;
    font-size: 14px;

    & span {
      flex-grow: 1;
    }
  }
  
  & li:hover {
    background-color: ${colors.neutral_80};
    cursor: pointer;
  }

  & li[aria-disabled='true'] {
    opacity: 0.4;
    pointer-events: none;
  }
  
  & li[aria-selected='true'] {
    opacity: 1;
    background-color: ${colors.primary_80};
    font-weight: 600;
  }
`;
});

type Props = {
  name: string;
  label: string;
  currentValues: OptionType[];
  options: OptionType[];
  disabled?: boolean;
  onChange: (options: OptionType[]) => void;
};

const CustomAutocomplete: FC<Props> = ({
  name,
  label,
  currentValues,
  options,
  disabled,
  onChange,
}) => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: name,
    multiple: true,
    defaultValue: currentValues,
    options: options,
    getOptionLabel: (option) => option.title,
    onChange: (_, value) => onChange(value),
  });

  return (
    <div>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}>{label}</Label>

        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option: OptionType, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Tag
                // @ts-ignore
                key={key}
                label={option.title}
                variant="outlined"
                color="secondary"
                deleteIcon={
                  <Icon
                    style={{ color: colors.black, flexShrink: 0 }}
                    icon="material-symbols:close"
                  />
                }
                {...tagProps}
              />
            );
          })}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>

      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => {
            // @ts-ignore
            const { key, ...optionProps } = getOptionProps({
              option,
              index,
            });

            const isSelected = currentValues.some(
              (opt) => opt.value === option.value,
            );

            return (
              <li
                key={key}
                {...optionProps}
                aria-disabled={disabled || isSelected}
                aria-selected={isSelected}
              >
                <span>{option.title}</span>
                {isSelected && (
                  <Icon
                    style={{ color: colors.black, flexShrink: 0 }}
                    icon="material-symbols:done"
                    width={16}
                  />
                )}
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </div>
  );
};

export default CustomAutocomplete;
