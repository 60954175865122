import React, { FC } from 'react';

import clsx from 'clsx';

import InfoLabel from '~/components/atoms/InfoLabel';

import styles from './styles.module.scss';

export interface TabProps {
  label: string;
  isActive: boolean;
  isError?: boolean;
  onClick: () => void;
  infoLabel?: string;
  disabled?: boolean;
  EndIcon?: FC;
}

const Tab: FC<TabProps> = ({
  label,
  isActive,
  isError,
  infoLabel,
  disabled,
  EndIcon,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(styles.tab, {
        [styles.is_active]: isActive,
        [styles.is_error]: isError,
        [styles.is_disabled]: disabled,
      })}
    >
      {label}
      {EndIcon ? <EndIcon /> : null}
      {infoLabel ? (
        <InfoLabel className={styles.tab__label} text={infoLabel} />
      ) : null}
    </div>
  );
};

export default Tab;
