import { FC } from 'react';

import { SignInPath } from '~/modules/auth/constants';
import { UsersPath } from '~/modules/users/constants';

export type RouteItemType = {
  path: string;
  Component: FC;
  title: string;
  permissionKey?: string;
  checkAvailability?: (features: any) => boolean;
};

class Router {
  private authRoutes: RouteItemType[] = [];
  private privateRoutes: RouteItemType[] = [];
  private signUpRedirectPath: string | null = null;

  constructor() {
    this.authRoutes = [];
    this.privateRoutes = [];
  }

  addAuthRoutes(route: RouteItemType[]): void {
    this.authRoutes = [...this.authRoutes, ...route];
  }

  getAuthRoutes(): RouteItemType[] {
    return this.authRoutes;
  }

  getSignInRoutePath(): string {
    return SignInPath;
  }

  addPrivateRoutes(route: RouteItemType[]): void {
    this.privateRoutes = [...this.privateRoutes, ...route];
  }

  getPrivateRoutes(): RouteItemType[] {
    return this.privateRoutes;
  }

  getDefaultPrivateRoutePath(): string {
    return UsersPath;
  }

  setRedirectSignUpPath(path: string | null): void {
    this.signUpRedirectPath = path;
  }

  getRedirectSignUpPath(): string | null {
    return this.signUpRedirectPath;
  }

  getPrivateRoutesByPermissions(permissionsList: string[]): RouteItemType[] {
    return this.privateRoutes.filter(
      ({ permissionKey }) =>
        !permissionKey || permissionsList.includes(permissionKey),
    );
  }

  getDefaultPrivateRouteByPermissions(
    permissionsList: string[],
  ): RouteItemType | null {
    const availablePrivateRoutes =
      this.getPrivateRoutesByPermissions(permissionsList);

    if (availablePrivateRoutes.length) {
      return availablePrivateRoutes[0];
    }
    return null;
  }
}

export default new Router();
