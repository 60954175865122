import React, { FC, useEffect, useRef } from 'react';

import { Icon } from '@iconify/react';
import { TextField as MuiTextField, styled } from '@mui/material';

import IconButton from '~/components/atoms/buttons/IconButton';
import useQueryParams from '~/hooks/useQueryParams';
import { colors } from '~/theme/colors';

import styles from './styles.module.scss';

const StyledTextField = styled(MuiTextField, {
  name: 'StyledTextField',
})(() => ({
  '& .MuiInputBase-root': {
    padding: '12px 56px 12px 25px',
    height: '48px',
    borderRadius: '12px',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
    backgroundColor: colors.white,

    '& fieldset': {
      border: '1px solid #E9E7EC',
    },

    '& input': {
      padding: 0,
      fontSize: '16px',
      letterSpacing: '0.5px',
      lineHeight: '24px',
    },

    '&.Mui-focused': {
      '& fieldset': {
        border: `2px solid ${colors.primary_40}`,
      },
    },

    '&.Mui-error': {
      '& fieldset': {
        border: `2px solid ${colors.error_30}`,
      },
    },
  },
}));

type Props = {
  isDisabled?: boolean;
  placeholder?: string;
  query: string;
  setQuery: (query: string) => void;
};

const SearchBar: FC<Props> = ({
  isDisabled,
  placeholder = 'Search',
  query,
  setQuery,
}) => {
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const { getQueryParams } = useQueryParams();
  const queryParams = getQueryParams();

  const handleInputFocus = (): void => {
    // analytics.trackEvent('navigation - input field focus');
  };

  const handleInputSubmit = (e): void => {
    e.preventDefault();

    if (
      textFieldRef.current &&
      (textFieldRef.current?.value.length || queryParams.searchText)
    ) {
      const trimmedValue = textFieldRef.current.value.trim();
      setQuery(trimmedValue);
    }
  };

  useEffect(() => {
    if (textFieldRef.current === null) {
      return;
    }
    textFieldRef.current.value = query;
  }, [query]);

  return (
    <form className={styles.container} onSubmit={handleInputSubmit}>
      <StyledTextField
        autoComplete="off"
        disabled={isDisabled}
        placeholder={placeholder}
        fullWidth
        defaultValue={query}
        onFocus={handleInputFocus}
        inputRef={textFieldRef}
      />
      <div className={styles.submit_button}>
        <IconButton
          color="primary"
          variant="contained"
          size="small"
          type="submit"
          disabled={isDisabled}
          onClick={handleInputSubmit}
        >
          <Icon fontSize={23} icon="material-symbols:search" />
        </IconButton>
      </div>
    </form>
  );
};

export default SearchBar;
