import React, { FC, useState } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';

import Button from '~/components/atoms/buttons/Button';

import styles from './styles.module.scss';

type Props = {
  isDisabled?: boolean;
  onClick: () => void;
  className?: string;
  text?: string;
};

const CopyButton: FC<Props> = ({ isDisabled, onClick, text, className }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = (): void => {
    onClick();

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <Button
      className={clsx(styles.button, className)}
      disabled={isDisabled}
      onClick={handleClick}
      variant="text"
      color="secondary"
      size="doubleExtraSmall"
    >
      <Icon
        icon={isCopied ? 'material-symbols:check' : 'ic:round-content-copy'}
        className={styles.button__icon}
      />
      {text && (
        <span className={styles.button__text}>
          {isCopied ? 'Copied' : text}
        </span>
      )}
    </Button>
  );
};

export default CopyButton;
