import React, { FC, useMemo } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { filterPermissionKeysByREADRule } from '~/utils/permissions';

interface Props {
  Component: FC;
}

const AuthRoute: FC<Props> = ({ Component }) => {
  const location = useLocation();
  const profile = useAppSelector((state) => state.profile);

  const filteredReadPermissions = filterPermissionKeysByREADRule(
    profile.permissions,
  );

  const defaultRedirectPath = router.getDefaultPrivateRouteByPermissions(
    filteredReadPermissions,
  );

  const redirectPath: string | null | undefined = useMemo(() => {
    if (!profile.isLoggedIn) {
      return null;
    }

    return location.state?.from || defaultRedirectPath?.path;
  }, [profile.isLoggedIn]);

  return redirectPath ? (
    <Navigate to={redirectPath} state={{ from: redirectPath }} />
  ) : (
    <Component />
  );
};

export default AuthRoute;
