import { UserType } from '~/modules/users/types';

export const UsersSidebarKey = 'Users';
export const UsersPath = '/users';
export const UserByIdPath = '/users/:id';
export const UsersTitle = 'Users';

export const initialUserData: UserType = {
  id: '',
  email: '',
  name: '',
  detectors_extra_words_left: 0,
  is_stripe_user: false,
  response_count_total: 0,
  is_onboarded: false,
  is_chrome_extension_installed: false,
  created_at: '2022-11-11T11:11:11Z',
  updated_at: '2022-11-11T11:11:11Z',
};

export enum USER_GROUP {
  General = 'General',
  Actions = 'Actions',
}
