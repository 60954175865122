import React, { FC } from 'react';

import {
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableBody,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import TableCell from '~/components/atoms/TableCell';
import TableCellHeader from '~/components/atoms/TableCell/TableCellHeader';
import TableCellRole from '~/components/atoms/TableCell/TableCellRole';
import TableCellStatus from '~/components/atoms/TableCell/TableCellStatus';
import TableContainer from '~/components/atoms/TableContainer';
import TableHead from '~/components/atoms/TableHead';
import TableRow from '~/components/atoms/TableRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { ADMIN_STATUSES, AdminsPath } from '~/modules/admins/constants';
import { AdminType } from '~/modules/admins/types';
import DateService from '~/services/Date';
import colors from '~/theme/colors.module.scss';
import { SORT_ORDER } from '~/types/common';

import styles from '../styles.modules.scss';

type FieldsValueType = {
  sortBy: 'created_at' | 'updated_at';
  sortOrder: SORT_ORDER;
};

type Props = {
  admins: AdminType[];
  filter: FieldsValueType;
  applyFilters: (filters: FieldsValueType) => void;
};

const TABLE_HEADER_SETTINGS = [
  {
    title: 'Id',
    key: 'id',
    sortable: true,
  },
  {
    title: 'Email',
    key: 'email',
    sortable: true,
  },
  {
    title: 'Role',
    key: 'role',
    sortable: true,
  },
  {
    title: 'Status',
    key: 'status',
    sortable: true,
  },
  {
    title: 'Created at',
    key: 'created_at',
    sortable: true,
  },
  {
    title: 'Updated at',
    key: 'updated_at',
    sortable: true,
  },
];

const AdminsTable: FC<Props> = ({ filter, applyFilters, admins }) => {
  const navigate = useNavigate();

  const handleClickSort = (field: 'created_at' | 'updated_at'): void => {
    const order =
      field === filter.sortBy && filter.sortOrder === SORT_ORDER.DESC
        ? SORT_ORDER.ASC
        : SORT_ORDER.DESC;

    applyFilters({
      sortBy: field,
      sortOrder: order,
    });
  };

  return (
    <div className={styles.table_container}>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <MuiTableRow>
              {TABLE_HEADER_SETTINGS.map((item) => {
                return (
                  <TableCellHeader
                    key={item.key}
                    handleClickSort={
                      item.sortable
                        ? (): void => {
                            handleClickSort(
                              item.key as 'created_at' | 'updated_at',
                            );
                          }
                        : undefined
                    }
                    order={item.key === filter.sortBy ? filter.sortOrder : null}
                  >
                    {item.title}
                  </TableCellHeader>
                );
              })}
            </MuiTableRow>
          </TableHead>

          <TableBody>
            {admins.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <span
                    onClick={(): void => {
                      navigate(`${AdminsPath}/${row.id}`);
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    {row.id}
                  </span>
                </TableCell>

                <TableCell>
                  <span>{row.email}</span>
                </TableCell>

                <TableCellRole role={row.role} />

                <TableCellStatus status={row.status as ADMIN_STATUSES} />

                <TableCell
                  sx={{ color: `${colors.color_neutral_800}!important` }}
                >
                  {DateService.format(
                    new Date(row.created_at),
                    READABLE_DATE_TIME_FORMAT,
                  )}
                </TableCell>

                <TableCell
                  sx={{ color: `${colors.color_neutral_800}!important` }}
                >
                  {DateService.format(
                    new Date(row.updated_at),
                    READABLE_DATE_TIME_FORMAT,
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
};

export default AdminsTable;
