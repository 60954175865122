import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { TableCellProps } from '@mui/material';

import { TableCellStyled } from '~/components/atoms/TableCell/index';
import { ADMIN_ROLES } from '~/modules/admins/constants';
import { colors } from '~/theme/colors';
import { snakeCaseToCapitalizeCase } from '~/utils/text';

const getRoleCell = (role: ADMIN_ROLES): JSX.Element => {
  switch (role) {
    case ADMIN_ROLES.SUPER_ADMIN:
      return (
        <>
          <Icon icon="bx:cool" style={{ color: colors.primary_20 }} />
          <span style={{ color: colors.primary_20 }}>
            {snakeCaseToCapitalizeCase(role)}
          </span>
        </>
      );

    default:
      return (
        <>
          <Icon icon="mdi:support" style={{ color: colors.primary_30 }} />
          <span style={{ color: colors.primary_30 }}>
            {snakeCaseToCapitalizeCase(role)}
          </span>
        </>
      );
  }
};

interface TableCellRoleProps extends TableCellProps {
  role: ADMIN_ROLES;
}

const TableCellRole: FC<TableCellRoleProps> = ({ role, ...props }) => {
  const rollCell = getRoleCell(role);

  return (
    <TableCellStyled
      {...props}
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {rollCell}
      </span>
    </TableCellStyled>
  );
};

export default TableCellRole;
