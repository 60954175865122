// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u0zditZRIkWOv6wsMGJ3{padding:24px 40px}.sR5RQfCWlJ0toVh1CD3J{padding:12px 40px 24px}.dYQ7ejplnvpC_oOxb8Am{padding:0 40px}.kMfCuioKYLBuMQr0F54N{padding:10px 20px}.fEFHV5tboS3cuF8m005D{display:flex;align-items:center;gap:8px}", "",{"version":3,"sources":["webpack://./src/modules/products/components/ProductItem/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAGA,sBACE,iBAAA,CAGF,sBACE,sBAAA,CAGF,sBACE,cAAA,CAGF,sBACE,iBAAA,CAGF,sBClBE,YADiB,CAGf,kBDiBoB,CACtB,OAAA","sourcesContent":["@import \"src/styles/mixins\";\n@import \"src/styles/colors\";\n\n.back_button {\n  padding: 24px 40px;\n}\n\n.header {\n  padding: 12px 40px 24px;\n}\n\n.container {\n  padding: 0 40px;\n}\n\n.content {\n  padding: 10px 20px;\n}\n\n.delete_button {\n  @include flex($align: center);\n  gap: 8px;\n}\n\n","@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {\n  display: $type;\n  @if ($align) {\n    align-items: $align;\n  }\n  @if ($justify) {\n    justify-content: $justify;\n  }\n  @if ($direction) {\n    flex-direction: $direction;\n  }\n}\n\n@mixin square($size) {\n  width: $size;\n  height: $size;\n}\n\n@mixin circle($size) {\n  width: $size;\n  height: $size;\n  border-radius: 50%;\n}\n\n@mixin backgroundClippedText($background) {\n  background: $background;\n  -webkit-background-clip: text;\n  background-clip: text;\n  background-size: cover;\n  color: transparent;\n}\n\n@mixin ignoreParentsWidth() {\n  width: 100vw;\n  margin-left: calc(50% - 50vw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back_button": "u0zditZRIkWOv6wsMGJ3",
	"header": "sR5RQfCWlJ0toVh1CD3J",
	"container": "dYQ7ejplnvpC_oOxb8Am",
	"content": "kMfCuioKYLBuMQr0F54N",
	"delete_button": "fEFHV5tboS3cuF8m005D"
};
export default ___CSS_LOADER_EXPORT___;
