import React, { FC, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '~/components/atoms/buttons/Button';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { emailSchema } from '~/helpers/validationSchemas';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const formSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('E-mail address is required')
      .test('test-email', 'Invalid Email', (value) => {
        if (value) return emailSchema.isValidSync(value);
        return true;
      }),
  })
  .required();

type Props = {
  isDisabled: boolean;
  currentEmail: string;
  onSubmit: (formData: { email: string }) => Promise<void>;
};

const Email: FC<Props> = ({ isDisabled, currentEmail, onSubmit }) => {
  const form = useForm<{
    email: string;
  }>({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset();
  }, [currentEmail]);

  return (
    <div className={styles.email}>
      <Typography variant={Typographies.HEADLINE_LARGE} component="div">
        Email ✉️
      </Typography>

      <Typography variant={Typographies.TITLE_MEDIUM} component="div">
        Current user email: <b>{currentEmail}</b>
      </Typography>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HookFormTextField
            required
            name="email"
            label="New Email"
            placeholder="Set new email"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <Button
            disabled={isDisabled}
            type="submit"
            variant="contained"
            className={styles.button}
          >
            Set email
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default Email;
