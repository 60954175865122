export enum BASE_RULE {
  'READ' = 'READ',
  'WRITE' = 'WRITE',
}

export enum PERMISSIONS_KEY {
  ADMINS = 'ADMINS',
  USERS = 'USERS',
  PRODUCTS = 'PRODUCTS',
}

export type PermissionsType = Record<
  PERMISSIONS_KEY,
  Record<BASE_RULE, boolean>
>;
