import React, { FC } from 'react';

import Button from '~/components/atoms/buttons/Button';

type Props = {
  isDowngradedEmpty?: boolean;
  isUpgradedEmpty?: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

const WarningModal: FC<Props> = ({
  isDowngradedEmpty,
  isUpgradedEmpty,
  onSubmit,
  onClose,
}) => {
  return (
    <div>
      Would you really like to <b>RESET</b>:
      <br />
      <br />
      <b>
        {isDowngradedEmpty && 'Downgraded plans'}
        {isUpgradedEmpty && (
          <>
            <br />
            Upgraded plans
          </>
        )}
      </b>
      <br />
      <br />
      <Button variant="contained" onClick={onSubmit}>
        Set
      </Button>
      <Button onClick={onClose}>Go back</Button>
    </div>
  );
};

export default WarningModal;
