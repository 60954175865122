import { useState } from 'react';

const useFilters = <T>(
  defaultFilters: T,
): {
  filters: T;
  updateFilters: (filters: Partial<T>) => void;
  resetFilters: (filters?: T) => void;
} => {
  const [filters, setFilters] = useState<T>(defaultFilters);

  return {
    filters: filters,
    updateFilters: (filters: Partial<T>): void => {
      setFilters((prev) => ({
        ...prev,
        ...filters,
      }));
    },
    resetFilters: (filters): void => {
      setFilters(filters || defaultFilters);
    },
  };
};

export default useFilters;
