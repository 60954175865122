export const SignInPath = '/sign-in';
export const SignInTitle = 'Log In';

export const SignUpPath = '/sign-up';
export const SignUpTitle = 'Sign Up';

export const ForgotPasswordPath = '/forgot-password';
export const ForgotPasswordTitle = 'Forgot Password';

export const ResetPasswordPath = '/reset-password';
export const ResetPasswordTitle = 'Reset Password';
