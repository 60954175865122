import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import {
  ExtendedUserType,
  SUBSCRIPTION_STATUS,
  SubscriptionType,
} from '~/modules/users/types';
import DateService from '~/services/Date';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const getSubscriptionFields = (
  sub: SubscriptionType,
  isStripeUser: boolean,
): [string, any][] => {
  return Object.entries({
    ID: sub.id,
    Status: sub.status || '-',
    Name: sub.subscription_name || '-',
    Price: sub.subscription_price || '-',
    'Billing Period': sub.billing_period || '-',
    'Payment Type': sub.payment_type || '-',
    'Is Unlimited': String(sub.is_unlimited),
    'Is Stripe User': String(isStripeUser),
    'Is Trial': String(sub.is_trial),
    'Started at': sub.started_at
      ? DateService.format(
          new Date(sub.started_at || ''),
          READABLE_DATE_TIME_FORMAT,
        )
      : '-',
    'Expired at': sub.expired_at
      ? DateService.format(
          new Date(sub.expired_at || ''),
          READABLE_DATE_TIME_FORMAT,
        )
      : '-',
    'Next charge at': sub.next_charge_at
      ? DateService.format(
          new Date(sub.next_charge_at || ''),
          READABLE_DATE_TIME_FORMAT,
        )
      : '-',
    ...(sub.status === SUBSCRIPTION_STATUS.CANCELLED
      ? {
          'Cancel code': sub.cancel_code || '-',
          'Cancel message': sub.cancel_message || '-',
          'Cancelled at': sub.cancelled_at
            ? DateService.format(
                new Date(sub.cancelled_at || ''),
                READABLE_DATE_TIME_FORMAT,
              )
            : '-',
        }
      : {}),
    ...(sub.status === SUBSCRIPTION_STATUS.PAUSED
      ? {
          'Pause from': sub.pause_from
            ? DateService.format(
                new Date(sub.pause_from || ''),
                READABLE_DATE_TIME_FORMAT,
              )
            : '-',
          'Pause to': sub.pause_to
            ? DateService.format(
                new Date(sub.pause_to || ''),
                READABLE_DATE_TIME_FORMAT,
              )
            : '-',
        }
      : {}),
  });
};

type Props = {
  user: ExtendedUserType;
};

const Subscription: FC<Props> = ({ user }) => {
  return (
    <div className={styles.sub}>
      <div className={styles.sub__info}>
        <Typography
          variant={Typographies.HEADLINE_LARGE}
          component="div"
          sx={{ '&&': { marginBottom: '24px' } }}
        >
          Subscription 📝
        </Typography>

        <div className={styles.sub__info_table}>
          {getSubscriptionFields(
            user.user_subscription,
            user.is_stripe_user,
          ).map(([key, value], idx) => (
            <div className={styles.sub__info_field} key={idx}>
              <div className={styles.sub__info_field_label}>{key}</div>
              <div className={styles.sub__info_field_value}>{value}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.sub__orders}>
        <div>
          <Typography
            variant={Typographies.HEADLINE_LARGE}
            component="div"
            sx={{ '&&': { marginBottom: '24px' } }}
          >
            IN-APP Purchases 💰
          </Typography>

          {user.orders ? (
            <div className={styles.sub__orders_table}>
              <div className={styles.sub__orders_table_head}>
                <div>Name</div>
                <div>Price</div>
                <div>Processed At</div>
              </div>

              {user.orders.map((order) => {
                return (
                  <div
                    className={styles.sub__orders_item}
                    key={order.processed_at}
                  >
                    <div className={styles.orders__item_name}>{order.name}</div>
                    <div className={styles.sub__orders_item_price}>
                      {order.amount}
                    </div>
                    <div className={styles.sub__orders_item_date}>
                      {DateService.format(
                        new Date(order.processed_at || ''),
                        READABLE_DATE_TIME_FORMAT,
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography
              variant={Typographies.HEADLINE_SMALL}
              component="div"
              sx={{ '&&': { marginBottom: '24px' } }}
            >
              No purchases yet 😢
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
