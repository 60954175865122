import React, { FC } from 'react';

import { styled, TableCellProps } from '@mui/material';

import { ReactComponent as EllipseIcon } from '~/assets/icons/ellipse.svg';
import { TableCellStyled } from '~/components/atoms/TableCell/index';
import { colors } from '~/theme/colors';

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'active':
      return colors.green_50;
    case 'invited':
      return colors.primary_50;
    case 'deactivated':
      return colors.error_50;
    case 'inactive':
      return colors.warning_60;
    default:
      return colors.warning_60;
  }
};

interface TableCellStatusProps extends TableCellProps {
  status: string;
}

const TableCellStatus: FC<TableCellStatusProps> = ({ status, ...props }) => {
  const color = getStatusColor(status);

  const EllipseIconStyled = styled(EllipseIcon)(() => ({
    '& circle': {
      fill: color,
    },
  }));

  return (
    <TableCellStyled
      {...props}
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      <EllipseIconStyled />
      <span
        style={{
          color,
          textTransform: 'capitalize',
          marginLeft: '8px',
        }}
      >
        {status}
      </span>
    </TableCellStyled>
  );
};

export default TableCellStatus;
