// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KP1hLM1EeaFp9TVG9oAQ{display:flex;align-items:center;justify-content:center;flex-wrap:nowrap;gap:32px;padding:16px 8px}.rtMrSrx1imqEBgxeeOSz{color:#708de4 !important;font-weight:700 !important}.xkD5Ek7nvgIFs2JXXEun{color:#46474a !important;font-weight:500 !important}", "",{"version":3,"sources":["webpack://./src/components/molecules/Pagination/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAGA,sBCFE,YADiB,CAGf,kBDCoB,CCEpB,sBDFsC,CACxC,gBAAA,CACA,QAAA,CACA,gBAAA,CAGF,sBACE,wBAAA,CACA,0BAAA,CAEA,sBACE,wBAAA,CACA,0BAAA","sourcesContent":["@import \"src/styles/colors\";\n@import \"src/styles/mixins\";\n\n.container {\n  @include flex($align: center, $justify: center);\n  flex-wrap: nowrap;\n  gap: 32px;\n  padding: 16px 8px;\n}\n\n.page {\n  color: $primary-50 !important;\n  font-weight: 700 !important;\n\n  &__muted {\n    color: $neutral-20 !important;\n    font-weight: 500 !important;\n  }\n}\n\n","@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {\n  display: $type;\n  @if ($align) {\n    align-items: $align;\n  }\n  @if ($justify) {\n    justify-content: $justify;\n  }\n  @if ($direction) {\n    flex-direction: $direction;\n  }\n}\n\n@mixin square($size) {\n  width: $size;\n  height: $size;\n}\n\n@mixin circle($size) {\n  width: $size;\n  height: $size;\n  border-radius: 50%;\n}\n\n@mixin backgroundClippedText($background) {\n  background: $background;\n  -webkit-background-clip: text;\n  background-clip: text;\n  background-size: cover;\n  color: transparent;\n}\n\n@mixin ignoreParentsWidth() {\n  width: 100vw;\n  margin-left: calc(50% - 50vw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "KP1hLM1EeaFp9TVG9oAQ",
	"page": "rtMrSrx1imqEBgxeeOSz",
	"page__muted": "xkD5Ek7nvgIFs2JXXEun"
};
export default ___CSS_LOADER_EXPORT___;
