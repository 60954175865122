import React, { FC, useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import Api from '~/Api';
import BlackTooltip from '~/components/atoms/BlackTooltip';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import { useModalContext } from '~/contexts/modal/ModalContext';
import CustomAutocomplete from '~/modules/products/components/ProductItem/MappingForm/CustomAutocomplete';
import SuccessModal from '~/modules/products/components/ProductItem/MappingForm/SuccessModal';
import WarningModal from '~/modules/products/components/ProductItem/MappingForm/WarningModal';
import { ProductType } from '~/modules/products/types';
import { Typographies } from '~/theme/typography';
import { SORT_ORDER } from '~/types/common';
import { FormDataType } from '~/types/form';

import styles from './styles.module.scss';

interface OptionType {
  title: string;
  value: string;
}

const MAX_DOWNGRADED_PLANS_LENGTH = 1;
const MAX_UPGRADED_PLANS_LENGTH = 2;

const extractOption = (product): OptionType => ({
  title: product.name,
  value: product.name,
});

enum FormFields {
  UPGRADED = 'upgraded',
  DOWNGRADED = 'downgraded',
}

type Props = {
  currentProduct: ProductType;
  onUpdateProduct: (data: FormDataType) => Promise<void>;
};

const MappingForm: FC<Props> = ({ currentProduct }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OptionType[] | null>(null);
  const [currentValues, setCurrentValues] = useState<Record<
    FormFields.UPGRADED | FormFields.DOWNGRADED,
    OptionType[]
  > | null>(null);

  const { handleOpenModal } = useModalContext();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setIsLoading(true);

        const [modifiedProducts, products] = await Promise.all([
          Api.getModifiedProductsById(currentProduct.id),
          Api.getProductsList({
            query: {
              sort_type: SORT_ORDER.ASC,
              sort_by: 'updated_at',
              page: 1, // TODO! How to get add??
              per_page: 100, // TODO! How to get all?
            },
          }),
        ]);

        setCurrentValues({
          [FormFields.DOWNGRADED]:
            modifiedProducts[FormFields.DOWNGRADED].map(extractOption),
          [FormFields.UPGRADED]:
            modifiedProducts[FormFields.UPGRADED].map(extractOption),
        });

        const productOptions = products.data.map(extractOption);

        setOptions(productOptions);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async (): Promise<void> => {
    if (!currentValues) {
      return;
    }

    try {
      setIsLoading(true);

      const isDowngradedEmpty =
        currentValues[FormFields.DOWNGRADED].length === 0;
      const isUpgradedEmpty = currentValues[FormFields.UPGRADED].length === 0;

      const onSubmit = (): void => {
        handleOpenModal({
          component: () => (
            <SuccessModal
              productName={currentProduct.name}
              formData={currentValues}
            />
          ),
        });
      };

      if (isDowngradedEmpty || isUpgradedEmpty) {
        handleOpenModal({
          component: ({ onClose }) => (
            <WarningModal
              isDowngradedEmpty={isDowngradedEmpty}
              isUpgradedEmpty={isUpgradedEmpty}
              onSubmit={onSubmit}
              onClose={onClose}
            />
          ),
        });

        return;
      }

      onSubmit();

      // await onUpdateProduct(filteredValues);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (newValue, name: FormFields): void => {
    setCurrentValues((prev) => {
      const updatedValues = {
        upgraded: prev?.[FormFields.UPGRADED] ?? [],
        downgraded: prev?.[FormFields.DOWNGRADED] ?? [],
      };
      updatedValues[name] = newValue;
      return updatedValues;
    });
  };

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} />

      <form className={styles.form}>
        {options && options.length > 0 && currentValues && (
          <div className={styles.form__fieldset}>
            <div className={styles.form__field}>
              <CustomAutocomplete
                name={FormFields.DOWNGRADED}
                label="Downgraded plans"
                options={options || []}
                currentValues={currentValues[FormFields.DOWNGRADED]}
                disabled={
                  currentValues[FormFields.DOWNGRADED].length >=
                  MAX_DOWNGRADED_PLANS_LENGTH
                }
                onChange={(newOptions): void =>
                  handleChange(newOptions, FormFields.DOWNGRADED)
                }
              />

              <BlackTooltip
                title={`You can select only ${MAX_DOWNGRADED_PLANS_LENGTH} plans. If you leave the field empty, it will reset the plans for this field.`}
              >
                <Icon
                  icon="material-symbols:info-outline"
                  className={styles.info}
                />
              </BlackTooltip>
            </div>

            <Icon
              icon="emojione:down-arrow"
              className={clsx(styles.arrow, styles['arrow--top'])}
            />

            <Typography variant={Typographies.TITLE_LARGE}>
              {currentProduct.name}
            </Typography>

            <Icon
              icon="emojione:down-arrow"
              className={clsx(styles.arrow, styles['arrow--bottom'])}
            />

            <div className={styles.form__field}>
              <CustomAutocomplete
                name={FormFields.UPGRADED}
                label="Upgraded plans"
                options={options || []}
                currentValues={currentValues[FormFields.UPGRADED]}
                disabled={
                  currentValues[FormFields.UPGRADED].length >=
                  MAX_UPGRADED_PLANS_LENGTH
                }
                onChange={(newOptions): void =>
                  handleChange(newOptions, FormFields.UPGRADED)
                }
              />

              <BlackTooltip
                title={`You can select only ${MAX_UPGRADED_PLANS_LENGTH} plans. If you leave the field empty, it will reset the plans for this field.`}
              >
                <Icon
                  icon="material-symbols:info-outline"
                  className={styles.info}
                />
              </BlackTooltip>
            </div>
          </div>
        )}
      </form>

      <div className={styles.buttons_container}>
        <Button
          style={{
            marginRight: 10,
            marginBottom: 10,
          }}
          type="submit"
          // disabled={isLoading}
          disabled
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Update product
        </Button>
      </div>
    </div>
  );
};

export default MappingForm;
