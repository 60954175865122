import React, { FC } from 'react';

import { Typography } from '@mui/material';

import IdeaImg from '~/assets/images/idea.webp';
import IdeaPreviewImg from '~/assets/images/idea_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  title: string;
  text?: string;
  onSubmit: () => Promise<void>;
  onRefuse: () => void;
};

const ConfirmationModal: FC<Props> = ({ title, text, onSubmit, onRefuse }) => {
  return (
    <div>
      <div className={styles.image}>
        <Image src={IdeaImg} preview={IdeaPreviewImg} />
      </div>

      <Typography
        variant={Typographies.TITLE_LARGE}
        className={styles.title}
        component="div"
      >
        {title}
      </Typography>

      {text && (
        <Typography
          variant={Typographies.TITLE_MEDIUM}
          className={styles.text}
          component="div"
        >
          {text}
        </Typography>
      )}

      <div className={styles.buttons_container}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Confirm
        </Button>

        <Button
          className={styles.button}
          variant="outlined"
          color="primary"
          onClick={onRefuse}
        >
          Decline
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
