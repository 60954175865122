import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import MockPhotoImg from '~/modules/users/assets/mock_photo.jpg';
import { ExtendedUserType } from '~/modules/users/types';
import DateService from '~/services/Date';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  user: ExtendedUserType;
};

const General: FC<Props> = ({ user }) => {
  return (
    <div className={styles.general}>
      <Typography
        variant={Typographies.HEADLINE_LARGE}
        component="div"
        sx={{ '&&': { marginBottom: '24px' } }}
      >
        General ⭐
      </Typography>

      <div className={styles.general__content}>
        <img
          src={user.avatar || MockPhotoImg}
          alt=""
          className={styles.general__avatar}
        />

        <div className={styles.general__table}>
          {Object.entries({
            ID: user.id,
            Name: user.name || '-',
            Email: user.email,
            'Created at': DateService.format(
              new Date(user.created_at),
              READABLE_DATE_TIME_FORMAT,
            ),
            'Is Extension Installed': String(
              user.is_chrome_extension_installed,
            ),
            'Response count': user.response_count_total,
          }).map(([key, value], idx) => (
            <div className={styles.general__field} key={idx}>
              <div className={styles.general__field_label}>{key}</div>
              <div className={styles.general__field_value}>{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default General;
