// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bWcDT01xJxA5QRecNTgw{color:#fff;border-radius:10em;line-height:1;padding:4px 6px;font-size:11px;background-color:#e24d39;display:inline-block;min-width:18px;text-align:center;font-weight:normal;font-family:\"Source Sans Pro\",sans-serif}", "",{"version":3,"sources":["webpack://./src/components/atoms/InfoLabel/styles.module.scss","webpack://./src/styles/_colors.scss","webpack://./src/styles/_fonts.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,wBCIS,CDHT,oBAAA,CACA,cAAA,CACA,iBAAA,CACA,kBAAA,CACA,wCEboB","sourcesContent":["@import \"src/styles/fonts\";\n@import \"src/styles/colors\";\n\n.label {\n  color: #fff;\n  border-radius: 10em;\n  line-height: 1;\n  padding: 4px 6px;\n  font-size: 11px;\n  background-color: $error-40;\n  display: inline-block;\n  min-width: 18px;\n  text-align: center;\n  font-weight: normal;\n  font-family: $content-font-family;\n}\n","$black: #000000;\r\n$white: #FFFFFF;\r\n\r\n$primary-20: #00164F;\r\n$primary-30: #13307E;\r\n$primary-40: #3B5AAE;\r\n$primary-50: #708DE4;\r\n$primary-80: #DBE1FF;\r\n\r\n$gradient-40: linear-gradient(90deg, #00B39B, #3B71F7);\r\n\r\n$error-20: #410001;\r\n$error-30: #7D0008;\r\n$error-40: #E24D39;\r\n$error-50: #FF8A7C;\r\n$error-80: #FFDAD5;\r\n\r\n$neutral-20: #46474A;\r\n$neutral-30: #76777A;\r\n$neutral-40: #AEAFB1;\r\n$neutral-60: #DBDCDF;\r\n$neutral-80: #EEF0F5;\r\n\r\n$warning-40: #F9E0A2;\r\n$warning-50: #F5D174;\r\n$warning-60: #EFB217;\r\n\r\n$green-30: #006B5D;\r\n$green-50: #00B39B;\r\n$green-70: #80D9CD;\r\n$green-90: #CCF0EB;\r\n","$headings-font-family: 'Source Serif Pro', sans-serif;\n$content-font-family: 'Source Sans Pro', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "bWcDT01xJxA5QRecNTgw"
};
export default ___CSS_LOADER_EXPORT___;
