import React, { FC, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import convertToYup from 'json-schema-yup-transformer';
import { FormProvider, useForm } from 'react-hook-form';

import Button from '~/components/atoms/buttons/Button';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormSwitch from '~/components/hookFormControls/SwitchControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import Tabs from '~/components/molecules/Tabs';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import {
  PRODUCT_GROUP,
  PRODUCT_GROUP_TABS,
  SCHEMA,
} from '~/modules/products/constants';
import { ProductType } from '~/modules/products/types';
import DateService from '~/services/Date';
import { FORM_FIELDS, FormDataType } from '~/types/form';
import { resetFormFields } from '~/utils/form';
import { updateErrorMessages } from '~/utils/validation';

import styles from './styles.module.scss';

type Props = {
  currentProduct: ProductType;
  onUpdateProduct: (data: FormDataType) => Promise<void>;
};

const OverviewForm: FC<Props> = ({ currentProduct, onUpdateProduct }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const [tabsWithError, setTabsWithError] = useState<PRODUCT_GROUP[]>([]);

  const form = useForm<FormDataType>({
    resolver: async (values, context, options) => {
      const primaryValidation = await yupResolver(
        convertToYup(SCHEMA.json_schema as Record<string, any>),
      )(values, context, options);

      return {
        ...primaryValidation,
        errors: updateErrorMessages(
          primaryValidation.errors,
          SCHEMA.json_schema,
          values,
        ),
      };
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { handleSubmit, setValue } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      await onUpdateProduct(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInvalidSubmit = async (
    data: Record<string, unknown>,
  ): Promise<void> => {
    const errorKeys = Object.keys(data);
    const errorInGroups = new Set();

    errorKeys.forEach((key) => {
      if (SCHEMA.ui_schema[key] && SCHEMA.ui_schema[key].group) {
        errorInGroups.add(SCHEMA.ui_schema[key].group);
      }
    });

    setTabsWithError(Array.from(errorInGroups) as PRODUCT_GROUP[]);
  };

  useEffect(() => {
    if (currentProduct) {
      resetFormFields(SCHEMA.ui_schema, setValue, currentProduct);
    }
  }, [SCHEMA.ui_schema, currentProduct]);

  const renderInput = useCallback(
    (type: FORM_FIELDS, props: any) => {
      switch (type) {
        case FORM_FIELDS.TEXT:
          return (
            <HookFormTextField
              {...props}
              size="small"
              InputLabelProps={{ shrink: true }}
              {...(props.defaultValue
                ? { defaultValue: props.defaultValue }
                : {})}
            />
          );
        case FORM_FIELDS.TEXTAREA:
          return (
            <HookFormTextField
              {...props}
              multiline={true}
              minRows={4}
              maxRows={10}
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          );
        case FORM_FIELDS.SELECT:
          return (
            // @ts-ignore
            <HookFormSelectField
              {...props}
              size="small"
              InputLabelProps={{ shrink: true }}
              {...(props.options.length
                ? { defaultValue: props.options[0].value }
                : {})}
            />
          );
        case FORM_FIELDS.TOGGLE:
          return (
            <HookFormSwitch
              {...props}
              size="medium"
              InputLabelProps={{ shrink: true }}
            />
          );
        default:
          return <></>;
      }
    },
    [isLoading],
  );

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.container__tabs}
        items={PRODUCT_GROUP_TABS.map((el, idx) => {
          return {
            label: el,
            isActive: activeTabIdx === idx,
            isError: tabsWithError.includes(el),
          };
        })}
        onClickItem={setActiveTabIdx}
      />

      <FormProvider {...form}>
        <form className={styles.form}>
          <div className={styles.form__fieldset}>
            {Object.entries(SCHEMA.ui_schema)
              .filter(
                ([_, value]) =>
                  value.group?.toLowerCase() ===
                  PRODUCT_GROUP_TABS[activeTabIdx].toLowerCase(),
              )
              .map(([name, value]) => {
                return (
                  <div className={styles.form__field} key={name}>
                    {renderInput(value.type, {
                      ...value.props,
                      name,
                      label: value.title,
                      fullWidth: true,
                    })}

                    {!value.props.required && (
                      <p className={styles.form__optional_label}>Optional</p>
                    )}
                  </div>
                );
              })}
          </div>
        </form>

        <div className={styles.extra_info}>
          <div className={styles.extra_info__title}>
            <div>Created at:</div>
            <div>Updated at:</div>
          </div>
          <div className={styles.extra_info__data}>
            <div>
              {DateService.format(
                new Date(currentProduct.created_at),
                READABLE_DATE_TIME_FORMAT,
              )}
            </div>
            <div>
              {DateService.format(
                new Date(currentProduct.updated_at),
                READABLE_DATE_TIME_FORMAT,
              )}
            </div>
          </div>
        </div>

        <div className={styles.buttons_container}>
          <Button
            style={{
              marginRight: 10,
              marginBottom: 10,
            }}
            type="submit"
            disabled
            // disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={(e): void => {
              handleSubmit(
                (data) => handleSuccessSubmit(data),
                (data) => handleInvalidSubmit(data),
              )(e);
            }}
          >
            Update info
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default OverviewForm;
