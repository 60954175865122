import React, { FC } from 'react';

import {
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableBody,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import TableCell from '~/components/atoms/TableCell';
import TableCellHeader from '~/components/atoms/TableCell/TableCellHeader';
import TableContainer from '~/components/atoms/TableContainer';
import TableHead from '~/components/atoms/TableHead';
import TableRow from '~/components/atoms/TableRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { initialUserData, UsersPath } from '~/modules/users/constants';
import { UserType } from '~/modules/users/types';
import DateService from '~/services/Date';
import { SORT_ORDER } from '~/types/common';

import styles from '../styles.modules.scss';

type FieldsValueType = {
  sortBy: 'created_at' | 'updated_at';
  sortOrder: SORT_ORDER;
};

type Props = {
  users: UserType[];
  filter: FieldsValueType;
  applyFilters: (filters: FieldsValueType) => void;
};

type TableHeaderSettingsType = {
  title: string;
  key: string;
  sortable: boolean;
};

const renderCellContent = (value: any): React.ReactNode => {
  if (value instanceof Date) {
    return DateService.format(new Date(value), READABLE_DATE_TIME_FORMAT);
  }

  if (typeof value === 'boolean') {
    return JSON.stringify(value);
  }

  if (!value) {
    return '-';
  }

  return value;
};

const convertKeysToTableHeaderSettings = (obj): TableHeaderSettingsType[] => {
  return Object.keys(obj).map((key) => ({
    title: String(key)
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (l) => l.toUpperCase()),
    key: key as string,
    sortable: true,
  }));
};

const TABLE_HEADER_SETTINGS: TableHeaderSettingsType[] =
  convertKeysToTableHeaderSettings(initialUserData);

const UsersTable: FC<Props> = ({ filter, applyFilters, users }) => {
  const navigate = useNavigate();

  const handleClickSort = (field: 'created_at' | 'updated_at'): void => {
    const order =
      field === filter.sortBy && filter.sortOrder === SORT_ORDER.DESC
        ? SORT_ORDER.ASC
        : SORT_ORDER.DESC;
    applyFilters({
      sortBy: field,
      sortOrder: order,
    });
  };

  return (
    <div className={styles.table_container}>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <MuiTableRow>
              {TABLE_HEADER_SETTINGS.map((item) => {
                return (
                  <TableCellHeader
                    key={item.key}
                    handleClickSort={
                      item.sortable
                        ? (): void => {
                            handleClickSort(
                              item.key as 'created_at' | 'updated_at',
                            );
                          }
                        : undefined
                    }
                    order={item.key === filter.sortBy ? filter.sortOrder : null}
                  >
                    {item.title}
                  </TableCellHeader>
                );
              })}
            </MuiTableRow>
          </TableHead>

          <TableBody>
            {users.map((row, rowIdx) => (
              <TableRow key={row.id}>
                {TABLE_HEADER_SETTINGS.map((header, cellIdx) => (
                  <TableCell
                    key={`${rowIdx}_${cellIdx}`}
                    sx={{
                      '&&': {
                        minWidth: header.key === 'id' ? '220px' : '160px',
                      },
                    }}
                  >
                    <span
                      {...(header.key === 'id'
                        ? {
                            onClick: (): void => {
                              navigate(`${UsersPath}/${row.id}`);
                            },
                            style: {
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            },
                          }
                        : {})}
                    >
                      {renderCellContent(row[header.key])}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
};

export default UsersTable;
