import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '~/components/atoms/buttons/Button';
import HookFormPassword from '~/components/hookFormControls/PasswordControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import { emailSchema, passwordSchema } from '~/helpers/validationSchemas';
import { ADMIN_ROLES } from '~/modules/admins/constants';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type FormDataType = {
  role: ADMIN_ROLES;
  email: string;
  password: string;
  confirmPassword: string;
};

const formSchema = yup
  .object()
  .shape({
    role: yup.string().required('Admin role is required'),
    email: yup
      .string()
      .required('E-mail address is required')
      .test('test-email', 'Invalid Email', (value) => {
        if (value) return emailSchema.isValidSync(value);
        return true;
      }),
    password: yup
      .string()
      .required()
      .test(
        'test-password',
        'Password must be at least 8 characters long and include at least one number,one capital letter and one small letter.',
        (value) => {
          if (value) return passwordSchema.isValidSync(value);
          return true;
        },
      ),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password')], 'Passwords must match'),
  })
  .required();

const ADMIN_ROLES_OPTIONS: Array<{
  text: string;
  value: string;
}> = [
  // TODO! The option to add a super admin is currently unavailable
  // {
  //   text: 'Super Admin',
  //   value: ADMIN_ROLES.SUPER_ADMIN as string,
  // },
  {
    text: 'Support Agent',
    value: ADMIN_ROLES.SUPPORT_AGENT as string,
  },
];

type Props = {
  onClose: () => void;
  onCreateAdmin: (
    role: ADMIN_ROLES,
    email: string,
    password: string,
  ) => Promise<void>;
};

const CreateAdminForm: FC<Props> = ({ onClose, onCreateAdmin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      role: ADMIN_ROLES.SUPPORT_AGENT,
      email: '',
    },
  });

  const { handleSubmit } = form;

  const handleCreateAdmin = async (formData: FormDataType): Promise<void> => {
    if (!formData.email) {
      return;
    }

    try {
      setIsLoading(true);
      await onCreateAdmin(formData.role, formData.email, formData.password);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.main_container}>
      <Typography
        variant={Typographies.HEADLINE_SMALL}
        sx={{ '&&': { marginBottom: '16px' } }}
        component="p"
      >
        New Admin
      </Typography>

      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleCreateAdmin)}>
          <div style={{ marginBottom: '8px' }}>
            <HookFormSelectField
              required
              name="role"
              label="Role"
              disabled={isLoading}
              fullWidth
              options={ADMIN_ROLES_OPTIONS}
            />
          </div>

          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="email"
              label="Email"
              disabled={isLoading}
              fullWidth
            />
          </div>

          <div style={{ marginBottom: '8px' }}>
            <HookFormPassword
              required
              name="password"
              label="New Password"
              disabled={isLoading}
              fullWidth
            />
          </div>

          <div style={{ marginBottom: '8px' }}>
            <HookFormPassword
              required
              name="confirmPassword"
              label="Confirm New Password"
              disabled={isLoading}
              fullWidth
            />
          </div>

          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Admin
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateAdminForm;
