import { BASE_RULE } from '~/constants/permissions';

export const filterPermissionKeysByREADRule = (
  permissions: Record<string, Record<string, boolean>>,
): string[] => {
  return Object.keys(
    Object.fromEntries(
      Object.entries(permissions).filter(([, value]) => value[BASE_RULE.READ]),
    ),
  );
};
