import React, { FC } from 'react';

import { ChipProps, Chip as MuiChip, styled } from '@mui/material';

import { colors } from '~/theme/colors';

export const StyledTag = styled(MuiChip, {
  name: 'StyledTag',
})(() => ({
  '&': {
    fontSize: '14px',
    fontWeight: 500,
    margin: '4px',
    background: colors.primary_50,
    borderRadius: '8px',
    padding: '0px',
    border: 'none',
    height: '32px',
    'text-overflow': 'ellipsis',
    'max-width': '230px',
  },

  '&.MuiChip-colorSecondary': {
    background: colors.primary_80,
    color: '#000',
  },

  '& .MuiChip-label': {
    paddingRight: '16px',
    paddingLeft: '12px',
  },

  '& .MuiChip-deleteIcon': {
    width: '18px',
    height: '18px',

    '&:hover path': {
      fill: colors.black,
    },
  },
}));

const Tag: FC<ChipProps> = ({ ...props }) => {
  return <StyledTag {...props} variant="outlined" />;
};

export default Tag;
