import React, { FC } from 'react';

import imgSrc from '~/assets/icons/logo.svg';

import styles from './styles.modules.scss';

const LogoWithText: FC = () => {
  return (
    <a href="/" className={styles.container}>
      <img className={styles.container__logo} src={imgSrc} alt="Justdone" />
      <div className={styles.container__text}>admin</div>
    </a>
  );
};

export default LogoWithText;
