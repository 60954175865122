import { FC } from 'react';

import {
  BASE_RULE,
  PERMISSIONS_KEY,
  PermissionsType,
} from '~/constants/permissions';

export type SideBarItemType = {
  path: string;
  Icon: FC<{ isActive?: boolean }>;
  title: string;
  permissionKey?: PERMISSIONS_KEY;
};

class Sidebar {
  private items: Record<string, SideBarItemType[]>;

  constructor(routes?: Record<string, SideBarItemType[]>) {
    this.items = routes ?? {};
  }

  addItems(routes: Record<string, SideBarItemType[]>): void {
    Object.entries(routes).forEach(([key, value]) => {
      if (this.items[key]) {
        this.items[key] = [...this.items[key], ...value];
      } else {
        this.items[key] = value;
      }
    });
  }

  getItems(): Record<string, SideBarItemType[]> {
    return this.items;
  }

  getItemsByPermissions(
    permissions: PermissionsType,
  ): Record<string, SideBarItemType[]> {
    return Object.fromEntries(
      Object.entries(this.items).map(([key, item]) => [
        key,
        item.filter(({ permissionKey }) => {
          return !permissionKey || permissions[permissionKey][BASE_RULE.READ];
        }),
      ]),
    );
  }
}

export default new Sidebar();
