import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';

import { Typographies } from '~/theme/typography';

const EmailUpdatingFailedModal: FC = () => {
  return (
    <div>
      <Icon
        icon="material-symbols:error-outline"
        style={{ color: 'red' }}
        width="36"
      />
      <br />
      <br />
      <Typography variant={Typographies.TITLE_LARGE} component="div">
        <b>Something went wrong :(</b>
      </Typography>
      <br />

      <Typography variant={Typographies.TITLE_MEDIUM} component="div">
        Try to set a new email in a few minutes.
        <br />
        If you still have any errors feel free to connect with Justdone team
      </Typography>
    </div>
  );
};

export default EmailUpdatingFailedModal;
