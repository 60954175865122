import React, { FC, useEffect, useMemo, useState } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import DeleteConfirmationModal from '~/components/modals/DeleteConfirmationModal';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { ProductListRoute } from '~/modules/products';
import MappingForm from '~/modules/products/components/ProductItem/MappingForm';
import OverviewForm from '~/modules/products/components/ProductItem/OverviewForm';
import { initialProductData } from '~/modules/products/constants';
import { ProductType } from '~/modules/products/types';
import { Typographies } from '~/theme/typography';
import { FormDataType } from '~/types/form';

import styles from './styles.module.scss';

enum PRODUCT_ITEM_GROUP {
  Overview = 'Overview',
  Mapping = 'Mapping',
}

const ProductItem: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<ProductType>(initialProductData);
  const [activeTab, setActiveTab] = useState(PRODUCT_ITEM_GROUP.Overview);

  const { showSnackbar } = useSnackBarContext();
  const { handleOpenModal } = useModalContext();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const loadProduct = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);

      const currentProduct = await Api.getProduct(id);

      setProduct(currentProduct);

      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      navigate('/');
    }
  };

  const handleUpdateProduct = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const updatedProduct = await Api.updateProduct(product.id, data);
      showSnackbar('Updated', 'success');
      setProduct(updatedProduct);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
    }
  };

  const handleDeleteProduct = async (): Promise<void> => {
    const onSubmit = async (): Promise<void> => {
      if (!id) {
        return;
      }

      try {
        setIsLoading(true);
        await Api.deleteProduct(id);
        showSnackbar(`Product ${id} is deleted `, 'success');
        setIsLoading(false);
        navigate(ProductListRoute.path);
      } catch (e: any) {
        showSnackbar(e.message);
        setIsLoading(false);
      }
    };

    handleOpenModal({
      component: ({ onClose }) => {
        return (
          <DeleteConfirmationModal
            title="Are you sure you'd like to delete this product?"
            onSubmit={async (): Promise<void> => {
              onClose();
              await onSubmit();
            }}
            onRefuse={onClose}
          />
        );
      },
    });
  };

  useEffect(() => {
    if (!id) {
      navigate('/');
      return;
    }

    loadProduct(id);
  }, []);

  const SCREENS = useMemo(() => {
    if (!product) {
      return;
    }
    return {
      [PRODUCT_ITEM_GROUP.Overview]: (
        <OverviewForm
          currentProduct={product}
          onUpdateProduct={handleUpdateProduct}
        />
      ),
      [PRODUCT_ITEM_GROUP.Mapping]: (
        <MappingForm
          currentProduct={product}
          onUpdateProduct={handleUpdateProduct}
        />
      ),
    };
  }, [product]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to Search"
        onClick={(): void => {
          navigate(ProductListRoute.path);
        }}
      />

      <TopHeadlineRow className={styles.header} text="Product card">
        <Button
          className={styles.delete_button}
          disabled
          variant="outlined"
          onClick={handleDeleteProduct}
        >
          <Icon icon="mdi:delete-outline" width="20" />
          <span>Delete product</span>
        </Button>
      </TopHeadlineRow>

      <div className={styles.container}>
        <Typography
          variant={Typographies.HEADLINE_LARGE}
          component="div"
          sx={{ '&&': { color: 'red' } }}
        >
          THIS MODULE IS STILL BEING DEVELOPED...
        </Typography>

        <Loader isLoading={isLoading}>
          <Tabs
            className={styles.container__tabs}
            items={Object.values(PRODUCT_ITEM_GROUP).map((el) => {
              return {
                label: el,
                isActive: activeTab === el,
              };
            })}
            onClickItem={(idx): void =>
              setActiveTab(Object.values(PRODUCT_ITEM_GROUP)[idx])
            }
          />

          <div className={styles.content}>{SCREENS && SCREENS[activeTab]}</div>
        </Loader>
      </div>
    </div>
  );
};

export default ProductItem;
