export const AdminsPath = '/admins';
export const AdminsTitle = 'Admins';
export const AdminsSidebarKey = 'Admins';

export const AdminByIdPath = '/admins/:id';

export enum ADMIN_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ADMIN_ROLES {
  SUPER_ADMIN = 'super_admin',
  SUPPORT_AGENT = 'support_agent',
}
