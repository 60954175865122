import { PermissionsType } from '~/constants/permissions';
import { ADMIN_ROLES, ADMIN_STATUSES } from '~/modules/admins/constants';
import {
  ProfileAction,
  ProfileActionTypes,
  ProfileState,
} from '~/store/types/profile';

const initialState: ProfileState = {
  id: '',
  isLoggedIn: false,
  email: '',
  permissions: {} as PermissionsType,
  role: '' as ADMIN_ROLES,
  status: '' as ADMIN_STATUSES,
  created_at: '',
  updated_at: '',
};

export const profileReducer = (
  state = initialState,
  action: ProfileAction,
): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.UPDATE_PROFILE:
      return { ...state, ...action.payload };
    case ProfileActionTypes.SIGN_IN:
      return { ...state, isLoggedIn: true };
    case ProfileActionTypes.SIGN_OUT:
      return { ...state, isLoggedIn: false };
    default:
      return state;
  }
};
