import React, { FC } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as SignOutIcon } from '~/assets/icons/sign_out.svg';
import LogoWithText from '~/components/atoms/LogoWithText';
import SidebarItem from '~/components/atoms/SidebarItem';
import { SideBarItemType } from '~/core/sidebar';
import { checkIsRouteMatch } from '~/helpers/routeMatcher';
import { useActions } from '~/store/hooks/useActions';

import styles from './styles.module.scss';

type Props = {
  items: SideBarItemType[][];
};

const SidebarLayout: FC<Props> = ({ items }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useActions();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.sidebar__logo_container}>
        <LogoWithText />
      </div>
      <div className={styles.sidebar__items}>
        {items.map((items, i) => (
          <div key={i}>
            {items.map(({ path, Icon, title }) => (
              <>
                <SidebarItem
                  isActive={checkIsRouteMatch(path, location.pathname)}
                  key={path}
                  Icon={Icon}
                  onClick={(): void => {
                    navigate(path);
                  }}
                  title={title}
                />
              </>
            ))}
            <div className={styles.sidebar__divider} />
          </div>
        ))}
      </div>
      <div>
        <SidebarItem
          isActive={false}
          Icon={SignOutIcon}
          onClick={(): void => {
            signOut();
          }}
          title="Log Out"
        />
      </div>
    </aside>
  );
};

export default SidebarLayout;
