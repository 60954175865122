// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h5WH054GHSVXfDQKzVL1{width:160px;margin:0 auto 32px}.bWc2l5WMSFIcxuvk8nFA{font-weight:700 !important}.vEezusUqaBUC1cpLnXQR{margin-top:12px !important;font-weight:400 !important}.mJzGTpCXVC_EMFNYnd7R{display:flex;align-items:center;gap:12px;margin-top:24px}.to0gn7WNFl3tyBKo9hTt{width:120px}", "",{"version":3,"sources":["webpack://./src/components/modals/ConfirmationModal/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,kBAAA,CAGF,sBACE,0BAAA,CAGF,sBACE,0BAAA,CACA,0BAAA,CAGF,sBCfE,YADiB,CAGf,kBDcoB,CACtB,QAAA,CACA,eAAA,CAGF,sBACE,WAAA","sourcesContent":["@import \"src/styles/mixins\";\n\n.image {\n  width: 160px;\n  margin: 0 auto 32px;\n}\n\n.title {\n  font-weight: 700 !important;\n}\n\n.text {\n  margin-top: 12px !important;\n  font-weight: 400 !important;\n}\n\n.buttons_container {\n  @include flex($align: center);\n  gap: 12px;\n  margin-top: 24px;\n}\n\n.button {\n  width: 120px;\n}","@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {\n  display: $type;\n  @if ($align) {\n    align-items: $align;\n  }\n  @if ($justify) {\n    justify-content: $justify;\n  }\n  @if ($direction) {\n    flex-direction: $direction;\n  }\n}\n\n@mixin square($size) {\n  width: $size;\n  height: $size;\n}\n\n@mixin circle($size) {\n  width: $size;\n  height: $size;\n  border-radius: 50%;\n}\n\n@mixin backgroundClippedText($background) {\n  background: $background;\n  -webkit-background-clip: text;\n  background-clip: text;\n  background-size: cover;\n  color: transparent;\n}\n\n@mixin ignoreParentsWidth() {\n  width: 100vw;\n  margin-left: calc(50% - 50vw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "h5WH054GHSVXfDQKzVL1",
	"title": "bWc2l5WMSFIcxuvk8nFA",
	"text": "vEezusUqaBUC1cpLnXQR",
	"buttons_container": "mJzGTpCXVC_EMFNYnd7R",
	"button": "to0gn7WNFl3tyBKo9hTt"
};
export default ___CSS_LOADER_EXPORT___;
