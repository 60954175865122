import React, { FC, ReactElement } from 'react';

import Desktop from '~/components/layoutComponents/SidebarLayout/Desktop';
import Mobile from '~/components/layoutComponents/SidebarLayout/Mobile';
import sidebar from '~/core/sidebar';
import useIsMobile from '~/hooks/useIsMobile';
import { useAppSelector } from '~/store/hooks/useAppSelector';

import Sidebar from './Sidebar';

const SidebarLayout: FC = ({ children }) => {
  const profile = useAppSelector((state) => state.profile);

  const isMobile = useIsMobile();
  const sidebarGroups = Object.values(
    sidebar.getItemsByPermissions(profile.permissions),
  );

  return isMobile ? (
    <Mobile Sidebar={(): ReactElement => <Sidebar items={sidebarGroups} />}>
      {children}
    </Mobile>
  ) : (
    <Desktop Sidebar={(): ReactElement => <Sidebar items={sidebarGroups} />}>
      {children}
    </Desktop>
  );
};

export default SidebarLayout;
