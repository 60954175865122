// here is the place where import new modules
import '~/modules/auth';
import '~/modules/users';

import '~/modules/admins';
import '~/modules/products';

import errorLogger from '~/services/ErrorLogger';

const SENTRY_DSN = process.env.SENTRY_DSN;

if (SENTRY_DSN) {
  errorLogger.init({ sentryDsn: SENTRY_DSN });
}
