import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Icon } from '@iconify/react';
import { capitalize } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import DeleteConfirmationModal from '~/components/modals/DeleteConfirmationModal';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { emailSchema } from '~/helpers/validationSchemas';
import useIsMobile from '~/hooks/useIsMobile';
import { AdminsListRoute } from '~/modules/admins';
import {
  ADMIN_ROLES,
  ADMIN_STATUSES,
  AdminsPath,
} from '~/modules/admins/constants';
import { AdminType } from '~/modules/admins/types';
import DateService from '~/services/Date';
import { snakeCaseToCapitalizeCase } from '~/utils/text';

import styles from './styles.module.scss';

type FormDataType = {
  email: string;
  status: ADMIN_STATUSES;
  role: ADMIN_ROLES;
};

const formSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('E-mail address is required')
      .test('test-email', 'Invalid Email', (value) => {
        if (value) return emailSchema.isValidSync(value);
        return true;
      }),
    status: yup.string().required('Password is required'),
  })
  .required();

const AdminItem: FC = () => {
  const { handleOpenModal } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState<null | AdminType>(null);
  const isMobile = useIsMobile();
  const { showSnackbar } = useSnackBarContext();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      status: ADMIN_STATUSES.INACTIVE,
      role: ADMIN_ROLES.SUPPORT_AGENT,
    },
  });

  const { handleSubmit, setValue } = form;

  const handleUpdateAdmin = async (data: FormDataType): Promise<void> => {
    if (!id) {
      return;
    }

    try {
      setIsLoading(true);
      await Api.updateAdmin(id, data);
      showSnackbar('Updated', 'success');
      // setAdmin(admin); // TODO! Check either we need to get admin or not
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
    }
  };

  const handleDeleteAdmin = async (): Promise<void> => {
    if (!id) {
      return;
    }

    const onSubmit = async (): Promise<void> => {
      try {
        setIsLoading(true);
        await Api.deleteAdmin(id);
        navigate(AdminsPath);

        showSnackbar('Deleted', 'success');
        // setAdmin(admin); // TODO! Check either we need to get admin or not
        setIsLoading(false);
      } catch (e: any) {
        showSnackbar(e.message);
        setIsLoading(false);
      }
    };

    handleOpenModal({
      component: ({ onClose }) => {
        return (
          <DeleteConfirmationModal
            title="Are you sure you'd like to delete this admin?"
            onSubmit={async (): Promise<void> => {
              onClose();
              await onSubmit();
            }}
            onRefuse={onClose}
          />
        );
      },
    });
  };

  useEffect(() => {
    if (!id) {
      navigate('/');
      return;
    }

    (async (id: string): Promise<void> => {
      try {
        setIsLoading(true);
        const admin = await Api.getAdmin(id);
        setAdmin(admin);
      } catch (e: any) {
        showSnackbar(e.message);
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    })(id);
  }, [id]);

  useEffect(() => {
    if (!admin) {
      return;
    }

    setValue('email', admin.email);
    setValue('status', admin.status);
  }, [admin]);

  return (
    <div>
      <Loader isLoading={isLoading} />

      {admin && (
        <>
          <BackButton
            className={styles.back_button}
            text="Back to Search"
            onClick={(): void => {
              navigate(AdminsListRoute.path);
            }}
          />

          <TopHeadlineRow
            className={styles.top_bar}
            text={`${snakeCaseToCapitalizeCase(admin.role)} Card`}
          >
            <Button
              className={styles.delete_button}
              variant="outlined"
              onClick={handleDeleteAdmin}
              disabled={admin.role === ADMIN_ROLES.SUPER_ADMIN}
            >
              <Icon icon="mdi:delete-outline" width="20" />

              <span>{`Delete ${snakeCaseToCapitalizeCase(
                admin.role,
              ).toLowerCase()}`}</span>
            </Button>
          </TopHeadlineRow>

          <div className={styles.container}>
            <div className={styles.form_container}>
              <FormProvider {...form}>
                <form>
                  <div className={styles.form_container__field}>
                    <HookFormTextField
                      required
                      name="email"
                      label="Email"
                      disabled
                      fullWidth
                      size={isMobile ? 'small' : 'medium'}
                    />
                  </div>

                  <div className={styles.form_container__field}>
                    <HookFormSelectField
                      required
                      name="status"
                      label="Status"
                      disabled={isLoading}
                      fullWidth
                      size={isMobile ? 'small' : 'medium'}
                      options={Object.values(ADMIN_STATUSES).map((value) => ({
                        text: capitalize(value),
                        value,
                      }))}
                    />
                  </div>

                  <div className={styles.extra_info}>
                    <div className={styles.extra_info__title}>
                      <div>Created at:</div>
                      <div>Updated at:</div>
                    </div>

                    <div className={styles.extra_info__data}>
                      <div>
                        {DateService.format(
                          new Date(admin.created_at),
                          READABLE_DATE_TIME_FORMAT,
                        )}
                      </div>

                      <div>
                        {DateService.format(
                          new Date(admin.updated_at),
                          READABLE_DATE_TIME_FORMAT,
                        )}
                      </div>
                    </div>
                  </div>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={admin.role === ADMIN_ROLES.SUPER_ADMIN}
                    onClick={handleSubmit(handleUpdateAdmin)}
                  >
                    Update info
                  </Button>
                </form>
              </FormProvider>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminItem;
