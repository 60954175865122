import React, { FC } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';

interface Props {
  Component: FC;
}

const PrivateRoute: FC<Props> = ({ Component }) => {
  const profile = useAppSelector((state) => state.profile);
  const signInPath = router.getSignInRoutePath();
  const { pathname, search } = useLocation();

  return profile.isLoggedIn ? (
    <Component />
  ) : (
    <Navigate
      to={signInPath}
      state={{
        from: pathname + (search ? search : ''),
      }}
    />
  );
};

export default PrivateRoute;
