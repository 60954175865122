import React from 'react';

import { Icon } from '@iconify/react';

import { PERMISSIONS_KEY } from '~/constants/permissions';
import router, { RouteItemType } from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import ProductItem from '~/modules/products/components/ProductItem';
import ProductList from '~/modules/products/components/ProductList';
import ProductNew from '~/modules/products/components/ProductNew';
import {
  ProductByIdPath,
  ProductNewPath,
  ProductsPath,
  ProductsSidebarKey,
  ProductsTitle,
} from '~/modules/products/constants';
import { colors } from '~/theme/colors';

const permissionKey = PERMISSIONS_KEY.PRODUCTS;

export const ProductListRoute: RouteItemType = {
  path: ProductsPath,
  Component: withSidebarLayout(ProductList),
  title: ProductsTitle,
  permissionKey,
};

export const ProductItemRoute: RouteItemType = {
  path: ProductByIdPath,
  Component: withSidebarLayout(ProductItem),
  title: ProductsTitle,
  permissionKey,
};

export const ProductNewRoute: RouteItemType = {
  path: ProductNewPath,
  Component: withSidebarLayout(ProductNew),
  title: ProductsTitle,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: ProductsPath,
      title: ProductsSidebarKey,
      Icon: ({ isActive }): JSX.Element => (
        <Icon
          icon="eos-icons:products"
          width="20"
          color={isActive ? colors.white : colors.primary_400}
        />
      ),
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([ProductListRoute, ProductNewRoute, ProductItemRoute]);
