import React, { FC } from 'react';

import { SwitchProps } from '@mui/material/Switch';
import { Controller, useFormContext } from 'react-hook-form';

import Switch from '~/components/atoms/inputs/Switch';

export type HookFormCounterProps = SwitchProps & {
  label: string;
  name: string;
};

const HookFormSwitch: FC<HookFormCounterProps> = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }): JSX.Element => (
        <Switch checked={value} onChange={onChange} {...props} />
      )}
    />
  );
};

export default HookFormSwitch;
