import React, { FC } from 'react';

interface OptionType {
  title: string;
  value: string;
}

type Props = {
  productName: string;
  formData: Record<'downgraded' | 'upgraded', OptionType[]>;
};

const SuccessModal: FC<Props> = ({ productName, formData }) => {
  return (
    <div>
      Plan <b>"{productName}"</b> is updated:
      <br />
      <br />
      <b>Downgraded plans</b>:{' '}
      {JSON.stringify(formData.downgraded.map((el) => el.title))}
      <br />
      <b>Upgraded plans</b>:{' '}
      {JSON.stringify(formData.upgraded.map((el) => el.title))}
    </div>
  );
};

export default SuccessModal;
