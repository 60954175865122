import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { ReactComponent as CatImgDesktop } from '~/assets/images/crying_cat_desktop.svg';
import Button from '~/components/atoms/buttons/Button';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  query: string;
  onReset: () => void;
};

const NotFound: FC<Props> = ({ query, onReset }) => {
  const handleResetClick = (): void => {
    onReset();
  };

  return (
    <div className={styles.container}>
      <div className={styles.img}>
        <CatImgDesktop />
      </div>

      <div className={styles.content}>
        <Typography
          variant={Typographies.TITLE_MEDIUM}
          className={styles.text}
          component="p"
        >
          Can't find a result with this criteria <br /> <b>{query}</b>
        </Typography>

        <Button variant="contained" color="primary" onClick={handleResetClick}>
          Clear filters
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
